import React from 'react';
import { Badge, Row, Col } from 'react-bootstrap';
import { PersonFill, Calendar } from 'react-bootstrap-icons';
import CONSTANTS from '../../../constants';
import PMTCONSTANTS from '../pmtConstants';

function PMTKanbanCard(props) {

  return (
    <>
    <div id={props.id} className={props.archived === true ? "lb-'kanban'-card archived" : "lb-kanban-card"} onClick={() => props.handleShowListItemModal(props.id)}>
      <label className='label-medium m-b-end-05 text-secondary'>{props.list_name}</label>
      <Row>
        <Col xs={11}>
          <h4 className='body-med m-b-end-05'>
          {
            props.archived && props.archived === true ?
              `* Archived * ${props.title ? props.title : '' }`
            :
            props.title ? props.title : ''
          }
          </h4>
        </Col>
        {/* TODO: Will add in check box for batch archiving later */}
        {/* <Col xs={1} className='tool-tip'>
          <input type='checkbox' />
        </Col> */}
      </Row>
        { 
          props.description && props.description.length > 0 ?
            <p>
                { props.description }
            </p>
          : null 
        }
        {
          //In the future may need to alter for future apps
          props.customField_3 && props.customField_3.length > 0 ?
            props.customField_3.map((item, idx) => {
              if (item) {
                return <Badge className={`${CONSTANTS.MULTI_SELECT_COLORS[idx]} m-i-end-03 badge-radius-lg`}>{item}</Badge>
              } else {
                return '';
              } 
            })
          : null
        }
        <Badge className='archived-badge badge-radius-lg label-medium'>{props.archived === true ? 'Archived': ''}</Badge>
        <Row className='m-b-start-05'>
          <p className='body-med'><Calendar className='m-i-end-03 m-b-end-02'/>{`${PMTCONSTANTS[props.appName.fields.appAlternateTitle].CUSTOM_KEYS.customField_7}: ${props.customField_7}`}</p>
        </Row>
        <Row>
          <Col xs={1}>
            <PersonFill />
          </Col>
          <Col className="person-responsible">
            <span className='body-med'>{props.assigned_to}</span>
          </Col>
        </Row>
    </div>
  </>
  )
}

export default PMTKanbanCard