import React, { useEffect, useState } from 'react';
import { Dropdown, Col, Row } from 'react-bootstrap';
import { ChevronDown } from 'react-bootstrap-icons';
import ListSkeletonLoader from './ListSkeletonLoader';

function DropdownWithSearch({handleSelect, showDeselect=false, id, className=null, fieldName=null, initialValue=null, menuItems, disabled=false, searchable=false, name=null}) {

    const [ searchTerm, setSearchTerm ] = useState();
    const [ selectedVal, setSelectedVal ] = useState();

    useEffect(() => {
      setSelectedVal(initialValue);
    },[initialValue])
    
    // When input is used filters dropdown options to current search
    const filterMenuItems = (items) => {
        if (searchTerm) {
          let retArr =  items.filter((name) => { 
          if (name) {
            return name.toLowerCase().includes(searchTerm.toLowerCase());
          }
          return''; 
            })
            return retArr;
        } else {
            return items;
        }
    }

    const handleSetOptionInState = (fldName, eKey) => {
      setSelectedVal(eKey === 'none' ? 'Select...' : eKey)
      handleSelect(fldName, eKey === 'none' ? '' : eKey)
    }

  return (
    <Dropdown
      name={name ? name : "Country"}
      onSelect={(eKey) => handleSetOptionInState(fieldName, eKey)}
      className={className}
      id={id}
    >
      <Dropdown.Toggle id='country-filter' className="align-left m-b-end">
        <Row>
          <Col lg={10} className='placeholder-text'>{selectedVal ? selectedVal : 'Select...'}</Col> <Col lg={2}><ChevronDown /></Col>
        </Row>
      </Dropdown.Toggle>
      

      <Dropdown.Menu>
        {/* Optional input to add a searchable dropdown */}
        {
            searchable === true ?
            <input
              autoFocus
              className="mx-3 my-2 w-auto"
              placeholder="Search..."
              onChange={(e) => setSearchTerm(e.target.value)}
            />
            : ''
        }
        {
          showDeselect === true ?
            <Dropdown.Item disabled={disabled} key='' eventKey={'none'}>Select...</Dropdown.Item>
          : ''
        }
        {
          menuItems && menuItems.length > 0 ?
            filterMenuItems(menuItems).map((item) => {
                return (
                    <Dropdown.Item disabled={disabled} key={item} eventKey={item}>{item}</Dropdown.Item>
                )
            })
          : <ListSkeletonLoader preview={false}/>
        }
      </Dropdown.Menu>
    </Dropdown>

  )
}

export default DropdownWithSearch