import React, {useState, useEffect} from 'react';
import { useIsAuthenticated } from '@azure/msal-react';
import Board, { moveCard } from '@asseinfo/react-kanban';

import CONSTANTS from '../../../constants';
import PMTKanbanCard from './PMTKanbanCard';
import utils from '../../../utils';
import PMTCONSTANTS from '../pmtConstants';
import { Spinner } from 'react-bootstrap';

function PMTKanbanView({topList, separateByField, onUpdate, tenant, handleReload, handleShowListItemModal, assigneesToPass, appName}) {
  const isAuthenticated = useIsAuthenticated();

  const [board, setBoard] = useState();
  const [loading, setLoading] = useState(true);
  const [boardLoading, setBoardLoading ] = useState(true);
  const abbreviateText = (text, lengthToAbbrev) => {
    let newText = text && text.length >= (lengthToAbbrev - 3) ? (text.substring(0, lengthToAbbrev-3) + '...' ) : text;
    return newText;
  }

  if(!separateByField || separateByField === ''){
    separateByField = 'status';
  }

  //takes custom_fields generic name and finds the field name that matches the workspace app
  const getAppCustomKeys = (apps, field) => {

    for (let app in apps) {
      switch (app.fields.appAlternateTitle) {
        case 'PMT MA':
          return PMTCONSTANTS.MARKET_ACTIONS_CUSTOM_KEYS[field];
        //leaving for future additions for additional PMT apps
        // case 'Next PMT':
        // case 'Another PMT':
        //   break;
        default:
          break;
      }
    }
  }

  //Calls functions that puts together board
  const getListItemInfo = (itemList, separateByField) => {
    let newBoard;

    if(itemList && itemList.length >= 1) {
      itemList.forEach((item) => {
          newBoard = { columns: []};
          newBoard.columns = getBoardColumns(separateByField);
      });
      newBoard.columns = putCardsIntoArrayUnderField(newBoard.columns, itemList, separateByField, appName);
      setBoard(newBoard);
    }
    
  }

  const updatedData = (id, fieldName, value) => {
    onUpdate({ id: id, field: fieldName, value: value }, topList);
  }

  const getBoardColumns = (fieldToSeparate) => {
    //this gets the list of possible iterations of a particular field, default is "status", but could be technically any field on listItem
    let returnedArray = [];

    //get a list of all possible values under the specific field - look in the first listItem custom fields if not available on full list.
    
    switch (fieldToSeparate) {
      case (!fieldToSeparate || 'status'):
        //get item.statuses
        returnedArray = [{ id: 'notSpecified', title: 'Not Specified', cards: []}]
        PMTCONSTANTS.STATUS_LIST.forEach((item) => {
          returnedArray.push({ id: item, title: utils.capitalCasing(item), cards: [] });
        })
        break;

      //Later on we will add the below on for once the sorter by filter is added
      case fieldToSeparate:
        PMTCONSTANTS[`${fieldToSeparate.toUpperCase()}_LIST`].forEach((item) => {
          returnedArray.push({ id: item, title: utils.capitalCasing(item), cards: [] });
        })
        break;
      default: 
        break;
    }
    return returnedArray;

  }

  const getHashMapOfFieldValuesToIndexesInColArray = (brdColArray) => {
    //this creates a hashmap for fast lookup on the indexes of the various field values (statuses, usually)
    //ie: goes from [{ status: 'not started' },...] to {'not started' : 0,...}
    let tempObj = {};
  
    for(let x=0; x < brdColArray.length; x++) {
      tempObj[brdColArray[x].title.toLowerCase()] = x;
    }

    return tempObj;
  }

  const getFullName = (email, list) => {
    if (email) {
      let name = list.filter((item) => {
        return item.mail === email
      })
      if (name && name.length > 0) {
        return name[0].displayName
      } else {
        email = email.split('@');
        return email[0];
      }
    }
  }

  const putCardsIntoArrayUnderField = (colArrayToAddTo, itemList, fieldToSeparate, app) => {
    //go through the items List, and put the card into the appropriate cards list
    let fieldNameToUse = fieldToSeparate;
    let tempHashMap = getHashMapOfFieldValuesToIndexesInColArray(colArrayToAddTo);

    if(fieldToSeparate === 'priority'){
      fieldNameToUse = 'Priority';
    }

    if(fieldToSeparate === 'customField_2') {
      fieldNameToUse = getAppCustomKeys(tenant.tenant.fields.apps)//Finds the field name to use for the customfield_2 
    }

    itemList.forEach( (thisItem) => {
      let valueToUse;

      if(fieldNameToUse === 'status'){
        valueToUse = thisItem.status;
      } else {
        if(thisItem.hasOwnProperty(fieldNameToUse.toLowerCase())) { 
          valueToUse = thisItem[fieldNameToUse.toLowerCase()];
        } else {
          if(thisItem.hasOwnProperty(CONSTANTS.MARKET_ACTIONS_KANBAN_CUSTOM_TO_FLAT[fieldNameToUse])) {
              valueToUse = thisItem[CONSTANTS.MARKET_ACTIONS_KANBAN_CUSTOM_TO_FLAT[fieldNameToUse]];
          } else {
            valueToUse = 'No Value Specified';
          }  
        }
      }

      //formats data to what the kanban card expects
      if(tempHashMap.hasOwnProperty(valueToUse.toLowerCase())) {
        colArrayToAddTo[tempHashMap[valueToUse.toLowerCase()]].cards.push({
          id: thisItem.item_id,
          due_date: thisItem.due_date,
          title: thisItem.item_name,
          description: thisItem.description ? abbreviateText(thisItem.description, 200) : '',
          list_name: thisItem.list_name,
          tooltip: thisItem.item_name,
          customField_3: thisItem.customField_3,
          assigned_to: getFullName(thisItem.assigned_to, assigneesToPass),
          archived: thisItem.deleted,
          customField_7: thisItem.customField_7,
        });
      } else {
        colArrayToAddTo[0].cards.push({
          id: thisItem.item_id,
          due_date: thisItem.due_date,
          title: thisItem.item_name,
          description: thisItem.description ? abbreviateText(thisItem.description, 200) : '',
          list_name: thisItem.list_name,
          tooltip: thisItem.item_name,
          assigned_to: getFullName(thisItem.assigned_to, assigneesToPass),
          archived: thisItem.deleted,
          customField_3: thisItem.customField_3,
          customField_7: thisItem.customField_7,
        });
      }
    })
    setBoardLoading(false);
    return colArrayToAddTo;
  }

  const updateList = (list, card, to, from, field) => {
    let tempList = [...list];
    let fieldName =CONSTANTS.MARKET_ACTIONS_GROUP_BY[field]

    tempList.map((int) => {
      if (int.id === card.id) {
        //problem here - the ID set is the text value, not
          int[fieldName] = to.toColumnId.toLowerCase();
          return tempList
      
      }
      return tempList;
    })
    
  }

  const onBoardChange = (_card, destination) => {

    //sets the field to change based on the field that we specify to columnize and the value to change to based on the column ID
    let theVal = destination.toColumnId;

    let changeObject = { taskId: _card.id};
    
    if(destination.toColumnId === 'notspecified'){
      changeObject[separateByField] = null;
    }else{
      changeObject[separateByField] = theVal;
    }
    //raise the update to the parent
    updatedData(_card.id, separateByField, theVal);

  }

  function handleCardMove(_card, source, destination) {
    
    onBoardChange(_card, destination);
    
    const updatedBoard = moveCard(board, source, destination);
    
    setBoard(updatedBoard);
    updateList(topList, _card, destination, source, separateByField)
  }

  useEffect(() => {
    if (isAuthenticated) {
      try {
        (async () => { 
          setLoading(true);
          getListItemInfo(topList, separateByField);
          setLoading(false);
        }
        )()
      } catch (err) {
        console.log(err);
        setLoading(false);
      }
    }
    //eslint-disable-next-line
  }, [isAuthenticated, separateByField, topList])

  return (
    <>
        { 
        !loading ?
        !boardLoading && board ?
            <>
            <Board
                onCardDragEnd={handleCardMove}
                disableColumnDrag
                allowRemoveLane 
                renderCard={(card, { removeCard, dragging }) => (
                <PMTKanbanCard
                  id={card.id} 
                  title={card.title}
                  description={card.description} 
                  list_name={card.list_name} 
                  tenant={tenant}
                  appName={appName}
                  tooltip={card.tooltip}
                  assigned_to={card.assigned_to}
                  archived={card.archived}
                  customField_3={card.customField_3}
                  customField_7={card.customField_7}
                  handleReload={handleReload}
                  handleShowListItemModal={handleShowListItemModal}
                  topList={topList}
                />  
                )}>
                {board}
            </Board>

            </>
        : <p>Crunching Board items - one minute</p>
        : 
         <Spinner animation='border' />
        }
    </>
  )
}

export default PMTKanbanView