import React, { useEffect, useState } from 'react';
import { Form, Row, Col, Badge, Spinner, Button } from 'react-bootstrap';
import BtnDynamic from '../Layout/BtnDynamic';
import {XLg} from 'react-bootstrap-icons';
import CONSTANTS from '../../constants';
import utils from '../../utils';
import DropdownDynamicWithSearch from '../Layout/DropdownDynamicWithSearch';
import EnumSelector from '../Alchemy/EnumSelector';
import PMTCONSTANTS from './pmtConstants';
import pmtutils from './pmtutils';

function ListItemViewModal({ show, apiKey, suppliers, appName=null, form, tenant, groupMembers, handleArchived, handleClose, onSubmit, errors, onChange, assigneesToPass, itemID, inputLoading, submitListItemComment, updateListItemComment, clearCommentInput, posting = false }) {
    const [showModal, setShowModal] = useState(true)

    const formatAssignees = (rawList) => {
        return rawList.map( (thisItem) => {
            return thisItem.mail;
        })
    }

    const formatDate = (date) => {
        if(date.includes('T')) {
            let dateStr = date.split('T');
            return dateStr[0]
        } else {
            return date;
        }
    }

    useEffect(() => {
        const dialog = document.querySelector("dialog");
            if (show === true) {
                dialog.showModal();
            } 
            if (show === false) {
                dialog.close();
            }
            if (showModal === false) {
                dialog.close();
            }
            dialog.addEventListener('click', function(event) {
                let rect = dialog.getBoundingClientRect();
                let isInDialog = (rect.top <= event.clientY && event.clientY <= rect.top + rect.height && rect.left <= event.clientX && event.clientX <= rect.left + rect.width);
                if (!isInDialog) {
                    dialog.close();
                    handleClose();
                }
            })
        },[show, showModal, handleClose])

    //content for dynamic button
    const archiveBtnContent = {
        name: form && form.deleted === true ? `Un-archive ${PMTCONSTANTS.LIST_ITEM_NAME[appName.fields.appAlternateTitle].toLowerCase()}` : `Archive ${PMTCONSTANTS.LIST_ITEM_NAME[appName.fields.appAlternateTitle].toLowerCase()}`
    }

    const convertToDate = (date) => {
        const newDate = new Date(date);
        const months = ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun', 'Jul', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec']
        const timeOfDay = newDate.getHours() >= 12 ? 'PM' : 'AM';

        const month = months[newDate.getMonth()];
        const day = newDate.getDate();
        const year = newDate.getFullYear();
        let hours = newDate.getHours() % 12;
        hours = hours ? hours : 12;
        const minutes = newDate.getMinutes();

        const formattedDate = `${month} ${day}, ${year} ${hours}:${minutes < 10 ? '0' : ''}${minutes} ${timeOfDay}`;
        return formattedDate;
    }

    //returns value for status dropdown
    const getStatusValue = (listItem = null, placeHolder) => {
        if (listItem) {
            let found = CONSTANTS.MARKET_ACTIONS_STATUS_SELECT.filter(
                (option) => {
                    return listItem && listItem.status && listItem.status.status && option.label.toLowerCase() === listItem.status.status
                }
            );
            if (found.length > 0) {
                return found[0].label;
            }
        }
        return placeHolder;
    }

    //Converts dropdown values to the expexted backend value and send to set in form
    const switchMultiToObjArray = (field, val) => {
        let multiArrObj = pmtutils.getMultiArrayObj(field, val, form.item_id?form.item_id:null);
        onChange(field, multiArrObj); 
    }

    const handleShowArchived = () => {
        setShowModal(!showModal)
        handleArchived();
    }

    // updates the comment object with the required field info
    const commentHandler = (e) => {
        let newDate = new Date();
        let commentObj = {};
        let userInfo = utils.findUserInfo(tenant.ourUserID, groupMembers)
     
        //Adds comment info to obj
        commentObj.intervention_id = form.item_id;
        commentObj.owner = userInfo[0].displayName;
        commentObj.created_on = newDate;
        commentObj.comment_value = e.target.value;
        updateListItemComment(commentObj);
    }

    return (
        <div className='modal-alignment'>
            <dialog id='int-modal' className='basic-dialog-styles' >
                <Form onSubmit={(e) => onSubmit(e)} className='max-width-100'>
                    <>
                        <Row>
                            <Col lg={11} className='p-i-end'>
                                <h4 className='m-b-end-03'>{PMTCONSTANTS.LIST_ITEM_NAME[appName.fields.appAlternateTitle]} Title{form && form.deleted && form.deleted === true ? <Badge className='descriptor-badge m-i-start-04 vertical-align'>Archived</Badge> : ''}</h4>
                                <Form.Group className="enter-item-name" controlId="item_id">
                                    {/* <input type="hidden" name="listID" value={listN} /> */}

                                    <Form.Control
                                        className={form && form.deleted === true ? 'semibold input-underline m-b-end-08' : 'input-underline m-b-end-08'}
                                        size="lg"
                                        type="text"
                                        as="textarea"
                                        rows={2}
                                        name="item_name"
                                        isDisabled={form && form.deleted === true}
                                        onChange={(val) => onChange(val.target.name, val.target.value)}
                                        placeHolder={'Enter text'}
                                        defaultValue={
                                            form && form.deleted === true ?
                                                `${"* Archived * " + form.item_name}`
                                                : form && form.item_name ?
                                                    form.item_name : ""
                                        }
                                        plaintext={
                                            form && form.deleted === true
                                        }
                                        readOnly={
                                            form && form.deleted === true
                                        }
                                        isInvalid={errors.hasOwnProperty("item_name")}
                                    />
                                    <Form.Control.Feedback type='invalid'>
                                        {errors.item_name}
                                    </Form.Control.Feedback>
                                </Form.Group>
                            </Col>
                            <Col className='align-right p-i-start' lg={1}>
                                <XLg onClick={() => handleClose(itemID ? itemID : '')} />
                            </Col>
                        </Row>
                        <Row className='m-b-end-05'>
                            <Col lg={6}>
                                <Form.Group className="status-select" controlId="status">
                                    <Form.Label className='subtitle-lg'>Status</Form.Label>
                                    <DropdownDynamicWithSearch
                                        showDeselect={false}
                                        handleSelect={onChange}
                                        initialValue={form && form.status ? form.status : getStatusValue(form, "Select...")}
                                        menuItems={PMTCONSTANTS.STATUS_LIST}
                                        className={'dropdown-select'}
                                        fieldName={'status'}
                                        disabled={form && form.deleted === true}
                                        searchable={false}
                                    />
                                </Form.Group>
                            </Col>
                            <Col lg={6}>
                                <Form.Label className='subtitle-lg'>Assignee</Form.Label>
                                <DropdownDynamicWithSearch
                                    showDeselect={true}
                                    handleSelect={onChange}
                                    initialValue={form && form.assigned_to ? form.assigned_to : "Select..."}
                                    menuItems={assigneesToPass ? formatAssignees(assigneesToPass) : []}
                                    className={'dropdown-select'}
                                    fieldName={'assigned_to'}
                                    disabled={form && form.deleted === true}
                                    searchable={true}
                                    id='assignee'
                                />
                            </Col>
                        </Row>
                        <Row>
                            <Col lg={6}>
                                <Form.Label className='subtitle-lg'>{PMTCONSTANTS[appName.fields.appAlternateTitle].CUSTOM_KEYS['customField_8']}</Form.Label>
                                <Form.Group>
                                    <Form.Control
                                        placeholder='Enter text'
                                        className='input-underline m-b-end-05'
                                        size="lg"
                                        type="text"
                                        as="textarea"
                                        rows='1'
                                        id={PMTCONSTANTS[appName.fields.appAlternateTitle].CUSTOM_FIELD_NAME['customField_8']}
                                        disabled={form && form.deleted === true}
                                        name={PMTCONSTANTS[appName.fields.appAlternateTitle].CUSTOM_FIELD_NAME['customField_8']}
                                        onChange={val => onChange(val.target.name, val.target.value)}
                                        defaultValue={form && form.customField_8 ? form.customField_8 : ''}
                                    >
                                    </Form.Control>
                                </Form.Group>
                            </Col>
                            <Col>
                                <Form.Label className='subtitle-lg'>{PMTCONSTANTS[appName.fields.appAlternateTitle].CUSTOM_KEYS['customField_4']}</Form.Label> 
                                <DropdownDynamicWithSearch
                                    id={PMTCONSTANTS[appName.fields.appAlternateTitle].CUSTOM_FIELD_NAME['customField_4']}
                                    handleSelect={onChange}
                                    initialValue={form && form.customField_4 ? form.customField_4 :''}
                                    menuItems={suppliers ? suppliers : []}
                                    className={'dropdown-select'}
                                    fieldName={PMTCONSTANTS[appName.fields.appAlternateTitle].CUSTOM_FIELD_NAME['customField_4']}
                                    disabled={form && form.deleted === true}
                                    showDefaultOption={true}
                                    searchable={true}
                                />
                            </Col>
                        </Row>
                        <Row>
                            <Form.Label className='subtitle-lg'>{PMTCONSTANTS[appName.fields.appAlternateTitle].CUSTOM_KEYS['customField_1']}</Form.Label>
                            <Form.Group>
                                <Form.Control
                                    placeholder='Enter text'
                                    className='input-underline m-b-end-05'
                                    size="lg"
                                    type="text"
                                    as="textarea"
                                    rows='2'
                                    id={PMTCONSTANTS[appName.fields.appAlternateTitle].CUSTOM_FIELD_NAME['customField_1']}
                                    disabled={form && form.deleted === true}
                                    name={PMTCONSTANTS[appName.fields.appAlternateTitle].CUSTOM_FIELD_NAME['customField_1']}
                                    onChange={val => onChange(val.target.name, val.target.value)}
                                    defaultValue={form && form.customField_1 ? form.customField_1 : ''}
                                >
                                </Form.Control>
                            </Form.Group>
                        </Row>
                        <Row>
                            <Form.Label className='subtitle-lg'>{PMTCONSTANTS[appName.fields.appAlternateTitle].CUSTOM_KEYS['customField_2']}</Form.Label>
                            <Form.Group>
                                <Form.Control
                                    placeholder='Enter text'
                                    size="lg"
                                    className='input-underline m-b-end-05'
                                    type="text"
                                    as="textarea"
                                    rows='3'
                                    id={PMTCONSTANTS[appName.fields.appAlternateTitle].CUSTOM_FIELD_NAME['customField_2']}
                                    disabled={form && form.deleted === true}
                                    name={PMTCONSTANTS[appName.fields.appAlternateTitle].CUSTOM_FIELD_NAME['customField_2']}
                                    onChange={val => onChange(val.target.name, val.target.value)}
                                    defaultValue={form && form.customField_1 ? form.customField_2 : ''}
                                >
                                </Form.Control>
                            </Form.Group>
                        </Row>
                        <Row className='m-b-end-05'>
                            <Col lg={6}>
                                <Form.Label className='subtitle-lg'>{PMTCONSTANTS[appName.fields.appAlternateTitle].CUSTOM_KEYS['customField_5']}</Form.Label>
                                <input
                                    id={PMTCONSTANTS[appName.fields.appAlternateTitle].CUSTOM_FIELD_NAME['customField_5']}
                                    className="striped-field input-underline full-width"
                                    name={PMTCONSTANTS[appName.fields.appAlternateTitle].CUSTOM_FIELD_NAME['customField_5']}
                                    disabled={form && form.deleted === true}
                                    defaultValue={form && form.customField_1 ? form.customField_5 : ''}
                                    onChange={(val) => onChange(val.target.name, val.target.value)}
                                />
                            </Col>
                            <Col lg={6}>
                                <Form.Label className='subtitle-lg'>{PMTCONSTANTS[appName.fields.appAlternateTitle].CUSTOM_KEYS['customField_6']}</Form.Label>
                                <input
                                    id={PMTCONSTANTS[appName.fields.appAlternateTitle].CUSTOM_FIELD_NAME['customField_6']}
                                    className="stakeholder-supporting-input input-underline full-width"
                                    name={PMTCONSTANTS[appName.fields.appAlternateTitle].CUSTOM_FIELD_NAME['customField_6']}
                                    disabled={form && form.deleted === true}
                                    defaultValue={form && form.customField_1 ? form.customField_6 : ''}
                                    onChange={(val) => onChange(val.target.name, val.target.value)}
                                />
                            </Col>
                        </Row>
                        <Row className='m-b-end-05'>
                            <Col lg={6}>
                                <Form.Label className='subtitle-lg'>Due date</Form.Label>
                                <input
                                    id='dueDate'
                                    disabled={form && form.deleted === true}
                                    className="input-date full-width"
                                    type="date"
                                    name="due_date"
                                    onChange={(val) => onChange(val.target.name, val.target.value)}
                                    defaultValue={form && form.due_date ? formatDate(form.due_date) : ""}
                                />
                            </Col>
                            <Col lg={6}>
                                <Form.Label className='subtitle-lg'>{PMTCONSTANTS[appName.fields.appAlternateTitle].CUSTOM_KEYS['customField_7']}</Form.Label>
                                <DropdownDynamicWithSearch
                                    id={PMTCONSTANTS[appName.fields.appAlternateTitle].CUSTOM_FIELD_NAME['customField_7']}
                                    handleSelect={onChange}
                                    initialValue={form && form.customField_7 ? form.customField_7 :''}
                                    menuItems={PMTCONSTANTS[appName.fields.appAlternateTitle].CUSTOM_FIELD_7_OPTIONS}
                                    className={'dropdown-select'}
                                    fieldName={PMTCONSTANTS[appName.fields.appAlternateTitle].CUSTOM_FIELD_NAME['customField_7']}
                                    disabled={form && form.deleted === true}
                                    showDefaultOption={true}
                                    searchable={false}
                                />
                            </Col>
                        </Row>
                        <Row className='m-b-end-05'>
                            <Col lg={6}>
                                <Form.Label className='subtitle-lg'>Priority</Form.Label>
                                <DropdownDynamicWithSearch
                                    id='priority'
                                    handleSelect={onChange}
                                    initialValue={form && form.priority ? form.priority : ''}
                                    formVal={form && form.priority ? CONSTANTS.MARKET_ACTIONS_PRIORITY_VAL_OBJ[form.priority] :''}
                                    menuItems={CONSTANTS.MARKET_ACTIONS_PRIORITY_ARRAY}
                                    className={'dropdown-select'}
                                    fieldName={'priority'}
                                    disabled={form && form.deleted === true}
                                    showDefaultOption={true}
                                    searchable={false}
                                />
                            </Col>
                            <Col lg={6}>
                                <Form.Label className='subtitle-lg'>{PMTCONSTANTS[appName.fields.appAlternateTitle].CUSTOM_KEYS['customField_3']}</Form.Label>

                                <EnumSelector
                                    enum_type={PMTCONSTANTS.PMT_ENUMS[appName.fields.appAlternateTitle].customField_3}
                                    apiKey={apiKey}
                                    isMulti={true}
                                    disableCreate={false}
                                    value={form && form.customField_3 ? form.customField_3 : []}
                                    isClearable={true}
                                    fieldName={PMTCONSTANTS[appName.fields.appAlternateTitle].CUSTOM_FIELD_NAME.customField_3}
                                    onChange={(e) => switchMultiToObjArray('customField_3', e)}
                                    className={'dropdown-select'}
                                />
                            </Col>
                        </Row>
                        <Row>
                        </Row>
                        {
                            itemID && itemID.toString().length > 0 ?
                                <>
                                    <h4 className='historty-header m-b-start-08'>Comments & Event History</h4>
                                    {
                                        form && form.comments && form.comments.length > 0 ?
                                            form.comments.map((comment, index) => {
                                                return (
                                                    <>
                                                        <Row key={index} className='p-03 comments'>
                                                            <Col><p>{comment.owner}</p></Col>
                                                            <Col className='comment-date-col'><p>{convertToDate(comment.created_on)}</p></Col>
                                                        </Row>
                                                        <p className='p-03'>{comment.comment_value}</p>
                                                    </>
                                                )
                                            })

                                            : <p className='p-03 text-secondary'>No comments yet...</p>
                                    }
                                </>
                                : ''
                        }
                        {
                            itemID && itemID.toString().length > 0 ?
                                <>
                                    <Row className='m-b-end-08 input-comment-container'>
                                        {
                                            !inputLoading ?
                                                <input
                                                    className='light-input-med-padding input-underline'
                                                    type="text"
                                                    id='comment_value'
                                                    name="comment_value"
                                                    disabled={form && form.deleted && form.archved === true ? true : false}
                                                    onChange={(e) => commentHandler(e)}
                                                    onKeyDown={(e) => {
                                                        if (e.key === "Enter") {
                                                            submitListItemComment(e);
                                                        }
                                                    }}
                                                    placeholder={"Enter comment here..."}
                                                />

                                                :
                                                <Spinner animation="border" />
                                        }
                                        <BtnDynamic
                                            classes={'intervention-comment-btns btn-ghost-lg'}
                                            content={{ name: 'Cancel' }}
                                            actionHandler={() => clearCommentInput()}
                                            loading={form && form.deleted && form.archved === true ? true : false}
                                        />
                                        <BtnDynamic
                                            classes={'surface-primary btn-ghost-border-lg intervention-comment-btns'}
                                            content={{ name: 'Comment' }}
                                            actionHandler={() => submitListItemComment()}
                                            loading={form && form.deleted && form.archved === true ? true : false}
                                        />
                                    </Row>
                                </>
                                : ''
                        }
                        <div className='btn-container'>
                            {/* why are we passing itemID back on discard? */}
                            <Button
                                onClick={() => handleClose(itemID ? itemID : '')}
                                className="btn-row btn-ghost-border-lg left-btn"
                            >
                                Discard changes
                            </Button>
                            {
                                itemID && itemID.toString().length > 0 ?
                                    <>
                                        <BtnDynamic
                                            classes={'btn-row btn-ghost-border-lg m-i-start-03 right-btn'}
                                            content={archiveBtnContent}
                                            actionHandler={() => handleShowArchived()}
                                        />
                                    </>
                                    : ''
                            }
                            <Button
                                disabled={posting}
                                onClick={(e) => onSubmit(e)}
                                className="surface-brand-primary btn-primary-lg btn-row m-i-start-03 right btn"
                            >
                                Save changes
                            </Button>
                        </div>
                    </>
                </Form>
            </dialog>
        </div>
    )
}

export default ListItemViewModal