import {Table} from 'react-bootstrap'

import '../../../styles/layout/_layout.scss'
import ProductEditorDrugSubstanceRow from '../Product/ProductEditorDrugSubstanceRow'

function ProductEditorDrugSubstance({product, setProduct, apiKey, organizations, disableProductEdits}) {
  /*----------------------State Variables-----------------------------------*/

  function addDrugSubstance(){
    // Creates a new organization row based on organization template
    const drug_substance = product.drug_substance
    drug_substance.push({
        product_id: null,
        org_id: null,
        production_location_id: null
    })
    setProduct({
        ...product,
        drug_substance:drug_substance
    })
}
  
  return  (
    <div>
      <Table size = "sm">
        <thead className = "table-heading">
          <tr>
            <th>Organization</th>
            <th>Production Location</th>
            {
              disableProductEdits?
              <th></th>:
              <th className="clickable-text"
                onClick={addDrugSubstance}>
                  add
              </th>
            }
            
          </tr>
        </thead>
        <tbody className ="table-body">
          {product.drug_substance?.map((o, i) => {
            return <ProductEditorDrugSubstanceRow 
              product={product}
              setProduct={setProduct}
              apiKey={apiKey}
              organizations={organizations}
              disableProductEdits={disableProductEdits}
              addDrugSubstance={addDrugSubstance}
              index={i}
            />
          })}
        </tbody>
      </Table>
      
    </div>
  );
}

export default ProductEditorDrugSubstance