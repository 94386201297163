import React, {useEffect, useState } from 'react'
import { Row, Col, Form } from 'react-bootstrap'
import { useMsal } from '@azure/msal-react';


import SearchTable from './SearchTable';
import utils from '../../utils'
import '../../styles/layout/_layout.scss';
import AlchemyToolbar from './AlchemyToolbar';

function StatefulSearchTable({ data, metaData, sortData, apiKey, module, onRowClick, dynamicWidths, mouseUp, buttons=null, secondRow=null, editor=null, settingsContent=null, settingsButtons=null, onFilteredIDsChange=null, toolbarButtons=null, appClass=null}){
  const [userSettings, setUserSettings]=useState();
  const userName = useMsal().accounts[0].username;
  const [filters, setFilters] = useState({});
  const [showDeleted, setShowDeleted] = useState(false);

  // Sort Options
  const [sortOrder, setSortOrder] = useState()
  const [sortOptions, setSortOptions] = useState([])


  // Sets sort initial order and options when sorted data is passed in
  useEffect(()=>{
    if(!sortData){
      return
    }
    // Takes the first row of the sort data as default argument
    setSortOrder({label:sortData[0].title, value:sortData[0]})
    setSortOptions(sortData.map((d)=>{return {label:d.title, value:d}}))
  }, [sortData])

  const handleSortChange = (newSortOrder) => {
    setSortOrder(newSortOrder);
  };



  // Get the current user settings if they exist, if not make them up.
  useEffect(()=>{
    async function getUserSettings(){
      const result = await utils.getData('get_user_fields', apiKey, '?user='+userName+'&module='+module)
      if(result){
        setUserSettings(result)
        return
      }
      setUserSettings({
        user:userName,
        module:module,
        dynamic_columns:false,
        dynamic_widths:false,
        user_fields:metaData.map((d, i)=>{
          return {
            field: d.title,
            sequence:i,
            width:Math.floor(1/metaData.length*100).toString()+'%'
          }
        })
      })
    }
    getUserSettings()
  }, [apiKey, module, userName, metaData])


  // Save Settings
  async function saveSettings(){
    const result = await utils.upsert('upsert_user_fields', apiKey, userSettings)
    if(!result){
      window.alert("Settings failed to save!")
    } else {
      setUserSettings(result)
    }
  }



  // Clear Filters
  const clearFilters = () => setFilters({});


  // This will have to be moved up for further abstraction.
  function handleWidthChange(e){
    // e will be something like [120px, 140px...]
    // and will always have as many elements as user_fields
    let tempFields = [...userSettings.user_fields]
    tempFields.forEach((elt, i)=>{
      elt.width=e[i]
    })
    setUserSettings({...userSettings,
      user_fields: tempFields
    })
  }

  function handleColumnChange(e){
    setUserSettings({...userSettings,
      user_fields: e.map((elt, i)=>{return {
        field:elt.title, 
        sequence:i,
        width:Math.floor(1/e.length*100).toString()+"%"
      }})
    })
  }

  function handleDynamicColumnChange(){
    // If they go from dynamic to non-dynamic then set their columns to all columns in the meta data
    // otherwise just toggle the feature on.
    if(userSettings.dynamic_columns){
      setUserSettings({...userSettings,
        user_fields: metaData.map((elt, i)=>{return {
            field:elt.title, 
            sequence:i,
            width:Math.floor(1/metaData.length*100).toString()+"%"
          }}),
        dynamic_columns:!userSettings.dynamic_columns
      })
    } else {
      setUserSettings({...userSettings, dynamic_columns:!userSettings.dynamic_columns})
    }
    
  }

  // KG TODO: Leave this for now
  function handleOptionsChange(newOptions) {
    console.log(newOptions); 
  }


  function columnRowContent() {
    return <>
      <Row>
        <Col xs={6}>
          <Form.Check type="checkbox"
            label='Dynamic Columns'
            className='input-text'
            onChange={handleDynamicColumnChange}
            checked={userSettings.dynamic_columns}
          />
        </Col>
        <Col xs={6}>
          <Form.Check type="checkbox"
            label='Dynamic Widths'
            className='input-text'
            onChange={() => setUserSettings({
              ...userSettings,
              dynamic_widths: !userSettings.dynamic_widths
            })}
            checked={userSettings.dynamic_widths}
          />
        </Col>
      </Row>
      <Row>
        <Col xs={6}>
          <Form.Check type="checkbox"
            label='Show Deleted'
            className='input-text'
            onChange={()=>setShowDeleted(!showDeleted)}
            checked={showDeleted}
          />
        </Col>
      </Row>
    </>
  }
  

  return (
    <>
      {userSettings ?
        <>
          <AlchemyToolbar
            onSortChange={handleSortChange}
            sortOptions={sortOptions}
            sortOrder={sortOrder}
            handleSortChange={handleSortChange}
            editor={editor}
            columnRowContent={columnRowContent}
            handleDynamicColumnChange={handleDynamicColumnChange}
            userSettings={userSettings}
            metaData={metaData}
            data={data}
            handleColumnChange={handleColumnChange}
            handleWidthChange={handleWidthChange}
            dynamicColumns={userSettings.dynamic_columns}
            dynamicWidths={userSettings.dynamic_widths}
            handleOptionsChange={handleOptionsChange}
            saveSettings={saveSettings}
            buttons={buttons}
            filters={filters}
            onClearFilters={clearFilters}
            settingsContent={settingsContent}
            toolbarButtons={toolbarButtons}
            settingsButtons={settingsButtons}
            mouseUp={mouseUp}
            appClass={appClass}
          />
          <SearchTable
            apiKey={apiKey}
            buttons={buttons}
            columnRowContent={columnRowContent()}
            secondRow={secondRow}
            data={data}
            metaData={metaData}
            onFilteredIDsChange={onFilteredIDsChange} 
            dispColSettings={userSettings.user_fields}
            sortData={sortData}
            onRowClick={onRowClick}
            editor={editor}
            filters={filters}
            setFilters={setFilters}
            dynamicWidths={userSettings.dynamic_widths}
            isResizable={dynamicWidths}
            handleWidthChange={handleWidthChange}
            sortOptions={sortOptions}
            setSortOptions={setSortOptions}
            sortOrder={sortOrder}
            setSortOrder={setSortOrder}
            onSortChange={handleSortChange}
            showDeleted={showDeleted}
          />
        </>
        : null}
    </>
  )
}

export default StatefulSearchTable;
