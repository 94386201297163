import React from 'react';
import PBIEmbeddedViz from '../../PBIEmbeddedViz';
import PMTCONSTANTS from '../pmtConstants';


function MarketActionsSummaryView() {
 

    return (
        <div>
            <PBIEmbeddedViz 
                reportID={PMTCONSTANTS['PMT MA'].DASH_PBI.reportID}
                datasetID={PMTCONSTANTS['PMT MA'].DASH_PBI.datasetID}
                initWidth={'100%'}
            />
        </div>
    )
}

export default MarketActionsSummaryView;