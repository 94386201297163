import PMTformatUtils from "../../PMTformatUtils";

const pmtutils = {
    getMultiArrayObj: (field, val, id) => {
        switch(field) {
            case 'customField_3':
                let retArr = val.map((item) => {
                    let obj = {}
                    let val;
                    if (item && item.label){
                        val = item.label
                    }
                    else if(item && item[0] && item[0].label) {
                        val = item[0].label;
                    }
                    else if(item && item.hmf_category) {
                        val = item.hmf_category;
                    } 
                    else {
                        val = item;
                    }

                    obj.hmf_category = val;
                    obj.intervention_id=id;
                    return obj;
                });
                return retArr;
            default:
            break;
        }
    },
    //Will add more cases as add apps
    //finds the correct formater to send the form data to to reformat to what is expected in the backend
    sendToFormatter: (app, formItem, user) => {
        switch (app.fields.appAlternateTitle) {
            case "PMT MA":
                let infoToSend =PMTformatUtils.deformatMarketActions(formItem, user);
                return infoToSend;
            default:
             break;
        }
    },
        
    getInitialLabelVals: (fieldArr) => {
        let retArr = [];
        if (fieldArr && fieldArr.length > 0) {
            fieldArr.forEach((item) => { 
                if(item && item.hmf_category) {
                    retArr.push(item.hmf_category)
                }
                if(item && item.label) {
                    retArr.push(item.label)
                }
            })
        }
        return retArr;
    }
}

export default pmtutils;