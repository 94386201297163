import React, {useState, useEffect} from 'react';
import { BoxArrowRight } from 'react-bootstrap-icons';
import { Row, Col, OverlayTrigger, Tooltip } from 'react-bootstrap';

function ReactTblTextAreaEditCell({showLinkIcon=false, handleShowItemModal, rowID, initialValue, column, updateMyData}) {

    const [myValue, setMyValue] = useState();
    const [firstLoad, setFirstLoad] = useState(true);

    useEffect(() => {
        if(firstLoad === true) {
            setMyValue(initialValue);
            setFirstLoad(false);
        }
    },[initialValue, firstLoad])
    
    const onChange = e => {
        setMyValue(e.target.value);
    };

    // We'll only update the external data when the input is blurred
    const onBlur = () => {
        updateMyData(rowID, column, myValue);
    };

    return (
        <div>
            <Row>
                <Col lg={showLinkIcon === true ? 10 : 12}>   
                    <OverlayTrigger 
                        placement='top'
                        overlay={
                        <Tooltip
                            placement='top'
                            className="in" 
                            id="tooltip-top"
                        >
                            {initialValue ? initialValue : ''}
                        </Tooltip>
                        }
                    >
                        <textarea 
                            rows={1}
                            name={column}
                            value={myValue ? myValue : ''}
                            onChange={(e) => onChange(e)}
                            onBlur={(e) => onBlur()}
                        ></textarea>
                    </OverlayTrigger>
                </Col>
                {
                    showLinkIcon === true ?
                        <Col lg={2}>
                            <BoxArrowRight onClick={() => handleShowItemModal(rowID)} />
                        </Col>
                    : ''
                }
            </Row>
        </div>
    )
}

export default ReactTblTextAreaEditCell