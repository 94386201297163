import React, { useState, useEffect } from 'react';
import Select from 'react-select';
import utils from '../../../utils';

function TableCellDropDown({ initialValue=null, rowID, column, updateMyData, selectionValues, forName, customField=null }) {

    const [ myValue, setMyValue ] = useState();
    const [ firstLoad, setFirstLoad ] = useState(true)

    const onChange = value => {
        setMyValue({label: value, value: value});
    };

    // We'll only update the external data when the input is blurred
    const onBlur = (row, col, val) => {
        if(forName === 'hmfCategory'){
            updateMyData(row, col, [val]);
        }else{
            updateMyData(row, col, val.value);
        }
        
    };
    
    // If the initialValue is changed external, sync it up with our state
    useEffect(() => {
        if(firstLoad === true && initialValue){
            let valObj = findValue(utils.capitalCasing(initialValue), customField) 
            setMyValue(valObj)
            setFirstLoad(false)
        }
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [initialValue, firstLoad, customField]);

    const findValue = (valToShow, custField) => {
        
        if((valToShow && valToShow !== null) || `${valToShow}` === '0') {
            let retVal = selectionValues.filter(option => {
                if(custField === true && valToShow && valToShow !== null) {
                    if(typeof valToShow !== 'string') {
                        valToShow = valToShow.toString();
                    }
                    return option && option.label && (`${option.label}`.toLowerCase() === valToShow.toLowerCase());
                } else {
                    return`${option.value}` === `${valToShow}`;
                }
                
            })
            retVal = retVal && retVal.length > 0 ? retVal[0] : retVal;
            return retVal;
        }
    }

    return (
        <>
            <Select 
                className="table-dropdown select-lg-typography"
                name={`select-${forName ? forName : 'select'}-${rowID}-${column}`} 
                options={selectionValues}  
                onChange={(val) => onChange(val.value)} 
                value={myValue ? myValue : initialValue ? initialValue : {label: 'Select...', value: 'Select'}} 
                onBlur={(e) => onBlur(rowID, column, myValue)}
            />
        </>
    )
}

export default TableCellDropDown;