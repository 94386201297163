import ReactTblSelectEditCell from "../../react-tbl-editable-cells/ReactTblSelectEditCell";
import PMTCONSTANTS from "../pmtConstants";
import ReactTblTextAreaEditCell from "../../react-tbl-editable-cells/ReactTblTextAreaEditCell";
import TableCellDropDown from "./TableCellDropDown";
import TableDatePicerCell from "./TableDatePickerCell";
import EnumSelector from "../../Alchemy/EnumSelector";



const PMTtableConfig = {
    getMarketActionsTableConfig: (appName, handleShowModal, updatedData, organization, assigneesToPass, priorities, tenant, key) => {
    //gets a list of available assignees for the assignee field
    const assigneesList = assigneesToPass.map((assignee) => {
      return {label: assignee.mail, value: assignee.mail};
    })

    return [
        {
          Header: PMTCONSTANTS[appName].CUSTOM_KEYS.customField_1,
          accessor: rowObj => {
            return (
                <ReactTblTextAreaEditCell
                    initialValue={rowObj.customField_1}
                    rowID={rowObj.item_id}
                    column={'customField_1'}
                    showLinkIcon={false}
                    updateMyData={updatedData}
                />
            )
          },
        }, {
          Header: PMTCONSTANTS[appName].CUSTOM_KEYS.customField_2,
          accessor: rowObj => {
            return (
              <ReactTblTextAreaEditCell
                initialValue={rowObj.customField_2}
                handleShowIntModal={handleShowModal}
                rowID={rowObj.item_id}
                column={'customField_2'}
                showLinkIcon={false}
                updateMyData={updatedData}
              />
            )
          },
        },
        {
          Header: PMTCONSTANTS.LIST_ITEM_NAME[appName],
          accessor: rowObj => {
            return (
              <ReactTblTextAreaEditCell
                initialValue={rowObj.item_name}
                handleShowIntModal={handleShowModal}
                rowID={rowObj.item_id}
                column={'item_name'}
                showLinkIcon={true}
                handleShowItemModal={handleShowModal}
                updateMyData={updatedData}
              />
            )
          },
          className: 'intervention-col',
        },
        {
          Header: PMTCONSTANTS[appName].CUSTOM_KEYS.customField_3,
          accessor: rowObj => {

            return (
              <EnumSelector
                className='hmfCategory'
                enum_type={PMTCONSTANTS.PMT_ENUMS[appName].customField_3}
                apiKey={key}
                isMulti={true}
                disableCreate={false}
                value={rowObj.customField_3 ? rowObj.customField_3 : []}
                isClearable={true}
                fieldName={PMTCONSTANTS[appName].CUSTOM_FIELD_NAME.customField_3}
                onChange={(e) => updatedData(rowObj.item_id, 'customField_3', e)}
              />
            )
          },
          className: 'hmfCategory',
        },
        {
          Header: PMTCONSTANTS[appName].CUSTOM_KEYS.customField_4,
          accessor: rowObj => {
            return (
              <ReactTblSelectEditCell
                initialValue={rowObj.customField_4}
                row={rowObj.item_id}
                column={'customField_4'}
                updateMyData={updatedData}
                selectionValues={organization}
                forName='vaccine'
              />
            )
          },
        },
        {
          Header: 'Assignee',
          accessor: rowObj => {
            return (
              <TableCellDropDown
                initialValue={rowObj.assigned_to}
                rowID={rowObj.item_id}
                column={'assignedTo'}
                updateMyData={updatedData}
                selectionValues={assigneesList}
                forName='assignedTo'
                customField={true}/>
            )
          },
        },
        {
          Header: PMTCONSTANTS[appName].CUSTOM_KEYS.customField_5,
          accessor: rowObj => {
            return (
              <ReactTblTextAreaEditCell 
                initialValue={rowObj.customField_5}
                handleShowIntModal={handleShowModal}
                rowID={rowObj.item_id}
                column={'customField_5'}
                showLinkIcon={false}
                updateMyData={updatedData}
              />
            )
          },
        },
        {
          Header: PMTCONSTANTS[appName].CUSTOM_KEYS.customField_6,
          accessor: rowObj => {
            return (
              <ReactTblTextAreaEditCell 
                initialValue={rowObj.customField_6}
                handleShowIntModal={handleShowModal}
                rowID={rowObj.item_id}
                column={'customField_6'}
                showLinkIcon={false}
                updateMyData={updatedData}
              />
            )
          },
        },
        {
          Header: 'Priority',
          accessor: rowObj => {
            return (
              <TableCellDropDown
                initialValue={rowObj.priority}
                rowID={rowObj.item_id}
                column={'priority'}
                updateMyData={updatedData}
                selectionValues={priorities}
                forName='priority' 
                customField={true}/>
            )
          },
        },  {
          Header: 'Due Date',
          accessor: rowObj => {
            return (
              <TableDatePicerCell
                initialValue={rowObj.due_date}
                row={rowObj.item_id}
                column={'due_date'}
                updateMyData={updatedData}
              />
            )
          }
        },
        {
          Header: PMTCONSTANTS[appName].CUSTOM_KEYS.customField_7,
          accessor: rowObj => {
            return (
              <ReactTblSelectEditCell
                initialValue={rowObj.customField_7}
                row={rowObj.item_id}
                column={'customField_7'}
                updateMyData={updatedData}
                selectionValues={PMTCONSTANTS[appName].CUSTOM_FIELD_7_SELECT}
                forName='dueDateTypes' />
            )
          },
        },
        {
          Header: 'Status',
          accessor: rowObj => {
            return (
              <TableCellDropDown
                initialValue={rowObj.status}
                rowID={rowObj.item_id}
                column={'status'}
                updateMyData={updatedData}
                customField={true}
                selectionValues={PMTCONSTANTS.STATUS_SELECT}
                forName='statuses'/>
            )
          },
        },
        {
          Header: 'Notes',
          accessor: rowObj => {
            return (
              <ReactTblTextAreaEditCell
                initialValue={rowObj.customField_9}
                handleShowIntModal={handleShowModal}
                rowID={rowObj.item_id}
                column={'customField_9'}
                showLinkIcon={false}
                updateMyData={updatedData}
              />
            )
          },
          className: 'intervention-col',
        }
    ]
  }
}

export default PMTtableConfig;